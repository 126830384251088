import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Storage } from '@ionic/storage';
import { RestService } from 'src/app/common/services/rest.service';
import { Clients } from './clients';
import { Client } from './client';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private restService: RestService, private storage: Storage) {}

  getClients(): Observable<Clients> {
    return this.restService.get(`/clients`).pipe(
      tap(async (res: Clients) => {
        return res;
      })
    );
  }

  getClient(clientId: string): Observable<Client> {
    return this.restService.get(`/clients/${clientId}`).pipe(
      tap(async (res: Client) => {
        return res;
      })
    );
  }

  updateClient(client: Client): Observable<Client> {
    return this.restService.put(`/clients/${client.id}`, client).pipe(
      tap(async (res: Client) => {
        return res;
      })
    );
  }

  insertClient(client: Client): Observable<Client> {
    return this.restService.post(`/clients`, client).pipe(
      tap(async (res: Client) => {
        return res;
      })
    );
  }

  deleteClient(clientId: string): Observable<Client> {
    return this.restService.delete(`/clients/${clientId}`).pipe(
      tap(async (res: Client) => {
        return res;
      })
    );
  }

}