export enum ReportsName {
    AssesmentReport = 'AssesmentReport',
    InspectionReport = 'InspectionReport',
    HazardReport = 'HazardReport',
    HazardObservation = 'HazardObservation',
    SafeActReport = 'SafeActReport',
    IncidentReport = 'IncidentReport',
    NewIncidentReport = 'NewIncidentReport',
    TaskRiskManagementReport = 'TaskRiskManagementReport',
    KPIReport = 'KPIReport', // key performance 
    LIPPIReport = 'LIPPIReport', // lead indicator positive performance
    LILSIReport = 'LILSIReport', // lead indicator leadership safety indicator
    LIBBSIReport = 'LIBBSIReport', // lead indicator Behavioural Based Safety Indicator
    CombinedMetricsReport = 'CombinedMetricsReport',
    ComparativeMetricsReport = 'ComparativeMetricsReport',
    PlanAndReview = 'PlanAndReview',
    Checklist = 'Checklist',

    DETAILEREPORT = "DETAILEREPORT",
    SINGLEPAGEREPORT = "SINGLEPAGEREPORT", //small reports, only single page reports
    DATAREPORT = "DATAREPORT", //DATA reports , where we do new ENTRY and EDIT of reports (client > data)
    SUMMARYREPORT = "SUMMARYREPORT", // INCIDENT REPORT - SUMMARY REPORT
}

export enum ReportsHeader {
    AssesmentReport = 'Task Assessment - Detailed Assessment Report',
    InspectionReport = 'Plant / Area Inspection - Detailed Inspection Report',
    HazardReport = 'Hazard Report - Detailed Hazard Report',
    HazardObservation = 'Hazard Observation - Detailed Hazard Observation',
    IncidentReport = 'Incident Investigation - Detailed Incident Investigation Report',
    NewIncidentReport = 'Incident Report - Detailed Incident Report',
    SafeActReport = 'Safe Act Report',
    //task assessment headers
    ComplianceByElement = "Task Assessment - Compliance by Element",
    ComplianceByCategory = 'Task Assessment - Compliance by Category',
    ElementMonthlyTrend = 'Task Assessment - Element Monthly Trend',
    RiskRating = 'Task Assessment - Risk Rating',
    MajorRiskCategories = 'Task Assessment - High Risk Activity Non-Compliance',
    SaferyRuleNonCompliance = 'Task Assessment - Behavioural Safety Rules',
    DepartmentContractorAssesed = 'Task Assessment - Departments / Contractors Assessed',
    AssessmentByPerson = 'Task Assessment - Assessments by Person',

    //inspection headers
    InspecitonComplianceByElement = "Plant / Area Inspection - Compliance by Element",
    InspecitonComplianceByCategory = 'Plant / Area Inspection - Compliance by Category',
    InspecitonElementMonthlyTrend = 'Plant / Area Inspection - Element Monthly Trend',
    InspecitonRiskRating = 'Plant / Area Inspection - Risk Rating',
    InspecitonMajorRiskCategories = 'Plant / Area Inspection - High Risk Activity Non-Compliance',
    InspecitonDepartmentContractor = 'Plant / Area Inspection - Department / Contractor Responsible',
    InspecitonAssessmentByPerson = 'Plant / Area Inspection - Inspections by Person',

    //hazard headers
    HazardDamagingEnergy = "Hazard Report - Damaging Energies Report",
    HazardProfile = 'Hazard Report - Hazard Profile',
    HazardReportsByPerson = 'Hazard Report - Hazard Reports by Person',
    HazardReportsDepartmentContractor = 'Hazard Report - Hazard Reports Department / Contractor (Working / Responsible)',
    HazardReportHighRiskActivityNonCompliance = 'Hazard Report - High Risk Activity Non-Compliance',
    HazardReportRiskRating = 'Hazard Report - Risk Rating',
    HazardReportUnsafeActsvsUnsafeConditions = 'Hazard Report - Unsafe Acts vs Unsafe Conditions',
    HazardSafetyAndBehaveRules = "Hazard Report - Behavioural Safety Rules",

    // Hazard Observation
    HazardObservationUnsafeActsvsUnsafeConditions = 'Hazard Observation - Unsafe Act/Unsafe Condition Report',
    HazardObservationRawNumbersReport = "Hazard Observation - Raw Numbers Report",

    //safe act headers
    SafeActReportsByPerson = 'Safe Act Report - Safe Act Reports by Person',
    SafeActReportsDepartmentContractor = 'Safe Act Report - Safe Act Reports Department / Contractor (Working / Responsible)',
    SafeActDetailedReport = 'Detailed Safe Act Report',
    SafeActHighRiskReport = 'Safe Act Report - High Risk Compliance',
    SafeActBehaviouralRuleComp = "Safe Act Report - Behavioural Safety Rule Compliance",

    //incident headers
    IncidentInvestigationDamagingEnergies = "Incident Investigation - Actual / Potential Failure Mechanism - Damaging Energies",
    IncidentInvestigationTop10Hazards = 'Incident Investigation - Actual / Potential Failure Mechanism - Top 10 Hazards',
    IncidentInvestigationBehaviouralSafetyRules = 'Incident Investigation - Behavioural Safety Rules',
    IncidentInvestigationHighRiskActivityNonCompliance = 'Incident Investigation - High Risk Activity Non-Compliance',
    IncidentInvestigationImmediateCauseAnalysis = 'Incident Investigation - Immediate Cause Analysis    ',
    IncidentInvestigationSystemOrganisationalCauses = 'Incident Investigation - System and Organisational Causes',

    //incident report headers
    IncidentReportRawNumbersReport = "Incident Report - Raw Numbers Report",
    IncidentReportDetailedReport = 'Incident Report - Detailed Report',
    IncidentReportSummaryReport = 'Incident Report - Incident Summary Report',

    // Lagging Indicators
    IncidentReportLaggingFreqTrend = 'Lagging Indicators - Lagging Frequency Rates - Trend Report',
    IncidentReportLaggingFreqMonthly = 'Lagging Indicators - Lagging Frequency Rates - Monthly Report',

    //risk headers
    TaskRiskManagementHazardProfile = "Task Risk Management - Hazard Profile",
    TaskRiskManagementDamagingEnergiesReport = 'Task Risk Management - Damaging Energies Report',
    TaskRiskManagementReport = 'Task Risk Management',

    //KPI headers
    KPIsMonthTrend = 'KPIs - Month by Month Trend',
    KPIsMonthlyReport = 'KPIs - Monthly Report',

    //lead indicators headers
    //behaviour
    LeadIndicatorsMonthlyPerformanceReport = "Lead Indicators - Behavioural Based Safety Indicator - Monthly Performance Report",
    LeadIndicatorsTrendReport = 'Lead Indicators - Behavioural Based Safety Indicator - Trend Report',
    //leadership
    LeadIndicatorsLeadershipSafetyIndicatorMonthlyPerformanceReport = 'Lead Indicators - Leadership Safety Indicator - Monthly Performance Report',
    LeadIndicatorsLeadershipSafetyIndicatorTrendReport = 'Lead Indicators - Leadership Safety Indicator - Trend Report',
    //positive
    LeadIndicatorsPositivePerformanceIndicatorMonthlyPerformanceReport = 'Lead Indicators - Positive Performance Indicator - Monthly Performance Report',
    LeadIndicatorsPositivePerformanceIndicatorTrendReport = 'Lead Indicators - Positive Performance Indicator - Trend Report',

    //combined metrics headers
    CombinedMetricsBehaviouralSafetyRules = "Combined Metrics - Behavioural Safety Rules (All Sources)",
    CombinedMetricsDamagingEnergies = 'Combined Metrics - Damaging Energies (Hazard Report & Task Risk Management)',
    CombinedMetricsHazardProfile = 'Combined Metrics - Hazard Profile (Hazard Report & Task Risk Management)',
    CombinedMetricsHighRiskActivityNonCompliance = 'Combined Metrics - High Risk Activity Non-Compliance (All Sources)',

    //comparative headers
    ComparativeMetricsHazardProfile = "Comparative Metrics - Hazard Profile (Task Risk Management / Hazard Report, Investigations)",
    ComparativeMetricsDamagingEnergies = 'Comparative Metrics - Damaging Energies (Task Risk Management / Hazard Report, Investigations)',

    // plan and review
    PlanAndReview = 'Plan & Review Report',

    // Checklist
    Checklist = "Detailed Checklist Report",
    ChecklistComplianceByCheckitem = "Compliance by Check item",
    ChecklistRiskRating = "Risk rating",
    ChecklistCriticalRisk = "Non-management of critical risk",
    ChecklistSafetyRules = "Breach of safety rules",
    ChecklistDetailedReport = "Detailed Report",
    ChecklistCompletedByAssessor = "Checklist completed by assessor",
}

export enum ChartType {
    LineChart = 'LineChart',
    BarChart = 'BarChart',
    PieChart = 'PieChart',
    MixedChart = 'MixedChart',
    KPIChart = 'KPIChart',
    KPIMbMChart = 'KPIMonthbyMonthChart',
    LineBarChart = 'bar'
}

export enum IncidentType{
    Injury = 'Injury',
    Environmental = 'Environmental',
    Damage = 'Damage',
    NMNC = 'Near Miss/Non-Compliance'
}